import "./assets/style/css/tailwind.css";
import "./assets/style/sass/main.scss";
import "./assets/style/sass/mixin.scss";
import VueApexCharts from "vue3-apexcharts";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import { vMaska } from "maska";
export default {
  install(app) {
    app.use(VueApexCharts);
    app.use(VCalendar, {});
    app.directive("maska", vMaska);
  },
};
