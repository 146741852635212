export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur le portail digital de transmission de PV d'accidents de l’ASA Bénin"])},
  "enterYourId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer vos identifiants pour vous connecter"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant ou email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color: #C6C6CA;'>Vous avez oublié votre mot de passe ?</span> <span style='color: #4945FF; cursor: pointer;'>Réinitialiser</span>"])},
  "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou mot de passe incorrects"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENU"])},
  "dashboardMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
  "sinisterMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PV Accidents"])},
  "userMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
  "logoutMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "madeByMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color: #B8BFCE;'>Propulsé par </span><span><a href='https://www.41devs.com/' target='_blank' style='color: #FFFFFF;'>41devs</a></span>"])},
  "accountFinalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalisation du compte"])},
  "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe pour finaliser la configuration de votre compte"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
  "continuousButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "activityFigures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiffres d’activité"])},
  "declaredSinister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PV Sinistres déclarés"])},
  "damagedVehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicules accidentés"])},
  "repayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversement à l’ASA"])},
  "amountDueMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant dû ce mois"])},
  "searchSinister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un PV"])},
  "exportButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
  "sinisterNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº Sinistre"])},
  "vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicule"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "declaration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration"])},
  "comingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de survenance"])},
  "activityPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d’activité"])},
  "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "startExportButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer l’exportation"])},
  "town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
  "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au"])},
  "filterByMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrage par mois"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procès verbal"])},
  "downloadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le document"])},
  "declaredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarée par"])},
  "sinisterPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu du sinistre"])},
  "victims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Victimes"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "createUserButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un utilisateur"])},
  "usernames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
  "completeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un utilisateur"])},
  "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom"])},
  "addUserButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter l’utilisateur"])},
  "fillPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs"])},
  "passwordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe et confirmation non conformes"])},
  "characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 caractères"])},
  "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contient un nombre"])},
  "uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contient une majuscule"])},
  "specialCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contient un caractère spécial"])},
  "leaveApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir vous déconnecter ?"])},
  "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation de mot de passe"])},
  "enterUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer votre identifiant pour finaliser la réinitialisation de mot de passe"])},
  "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un nouveau mot de passe pour finaliser la réinitialisation"])},
  "successCreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte créé avec succès. Un lien vous a été envoyé par mail pour définir votre mot de passe."])},
  "existingUsernameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant existant"])},
  "noUserAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur ajouté"])},
  "searchMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un PV"])},
  "sinisterComingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de survenance du sinistre"])},
  "minutesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº du PV"])},
  "sinistersNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº du sinistre"])},
  "matriculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immatriculation"])},
  "startSearchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer la recherche"])},
  "policyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du commissariat"])},
  "insuredName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'assuré"])},
  "noSinisterAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun PV de sinistre ajouté"])}
}